import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getSectionFormData, getSectionInfo } from "./selectors";
import { RegistrationItemTypes } from "constants/enums";
import { ColumnGrid } from "components/containers";
import RegistrationFormField from "./RegistrationFormField";
import { CustomErrors, YesNoRadioGroup } from "components/forms";
import { WiltonPark } from "constants/contacts";

const HoldsValidUkVisaItem = ({
  item,
  editControl,
  isVirtualAttendance,
  gridItemProps,
  sectionId,
}) => {
  const section = useSelector((s) => getSectionInfo(s, sectionId));
  const sectionFormData = useSelector((s) => getSectionFormData(s, sectionId));

  // check if visa required question exists in this section and get its answer
  const requireVisaAnswer = useMemo(() => {
    const questions = section?.groups.flatMap((g) => g.items);
    const requireVisaQuestion = questions?.find(
      (x) => x.type == RegistrationItemTypes.UkVisaRequired
    );
    if (requireVisaQuestion) {
      return sectionFormData[requireVisaQuestion.id];
    }

    return null;
  }, [section, sectionFormData]);

  const validateRequired = ({ fields }) => {
    if (fields[item.id] == undefined || fields[item.id] == null) {
      return ["This field is required"];
    }
    return [];
  };
  return isVirtualAttendance || requireVisaAnswer != true ? null : (
    <ColumnGrid.Item {...gridItemProps}>
      <div style={{ flexGrow: 1 }}>
        <RegistrationFormField
          item={item}
          as={YesNoRadioGroup}
          showErrors={false}
          className={item.required ? "required" : null}
          extraLabelContent={
            <span className="sub-header">
              If you require a visa to enter the United Kingdom, a visa support
              letter can be provided by Wilton Park but does not guarantee the
              issue of a visa. To provide you with a visa support letter, please
              email{" "}
              <a href={`mailto:${WiltonPark.VisaEmail}`}>
                {WiltonPark.VisaEmail}
              </a>{" "}
              with your full name as it appears on your passport, full address,
              date of birth and passport number.
            </span>
          }
        />
        {item.required && (
          <CustomErrors
            field={item.id}
            triggerFields={[item.id]}
            validator={validateRequired}
          />
        )}
      </div>
      {editControl}
    </ColumnGrid.Item>
  );
};

export default HoldsValidUkVisaItem;
